<template>
  <div class="tabs-body-style-color">
    <div
      class="tabs-menu-style"
      :class="{
        'tabs-menu-style-inactive': showTabs == false,
        'tabs-menu-style-active': showTabs == true
      }"
    >
      <img
        src="../../assets/Icon ionic-md-arrow-dropright-circle.png"
        class="flesh-icon"
        :class="{ 'flesh-icon-rigth': showTabs == false }"
        @click="showTabs = !showTabs"
      />
      <div class="palatte-item ">
        <div
          style="background-color:#9e69ee"
          class="color-cercle"
          title="En cours de validation"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          En cours de validation
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(238 149 166)"
          class="color-cercle"
          title="Non validé"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Non validé
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(232 160 53)"
          class="color-cercle"
          title="Validé"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Validé
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(120 70 70)"
          class="color-cercle"
          title="Annuler"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Annuler
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(32 100 232)"
          class="color-cercle"
          title="En cours de vérification"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          En cours de vérification
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(255 35 35)"
          class="color-cercle"
          title="Facture réfusé"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Facture réfusé
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(234 221 31)"
          class="color-cercle"
          title="Facture validé"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Facture validé
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(36 202 228)"
          class="color-cercle"
          title="En cours de paiement"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          En cours de paiement
        </div>
      </div>
      <hr class="ligne" />
      <div class="palatte-item">
        <div
          style="background-color:rgb(27 220 67)"
          class="color-cercle"
          title="Payé"
        ></div>
        <div
          class="label-color-palette"
          :class="{ 'show-label-palette': showTabs == true }"
        >
          Payé
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTabs: false
    };
  }
};
</script>

<style scoped lang="scss">
.tabs-body-style-color {
  display: flex;
  align-items: center;
  .palatte-item {
    align-items: center;
    margin: 0px 1px;
    color: #5d5d5d;
    font-family: Montserrat;
    font-size: 9.5px;
    font-weight: 500;
    padding: 1px;
    border-radius: 17px;

    .color-cercle {
      margin: auto;
      height: 17px;
      width: 17px;
      border-radius: 17px;
    }
    .label-color-palette {
      display: none;
    }
    .show-label-palette {
      display: contents;
    }
  }
  .tabs-menu-style {
    height: 100%;
    margin-right: 8px;
  }
  .tabs-menu-style-active {
    width: 119px !important;
  }
  .tabs-menu-style-inactive {
    width: 60px !important;
    .color-cercle {
      margin-bottom: 15%;
      margin-top: 15%;
    }
  }
  .ligne-verticale {
    height: 18px;
    width: 1px;
    background-color: #e9e9e9;
  }
}
</style>
